import { useTrackEvent } from '@troon/analytics';
import { Dialog, DialogTrigger, TroonAccess } from '@troon/ui';
import { createSignal, createEffect, onMount } from 'solid-js';
import { AccessProductDialogContent } from './dialog-content';

type UpsellProps = {
	location: string;
};

export function UsellAccessMinimal(props: UpsellProps) {
	const [open, setOpen] = createSignal(false);
	const trackEvent = useTrackEvent();

	onMount(() => {
		trackEvent('upsellVisible', { productType: 'troonAccess', location: props.location }, { noDuplicates: true });
	});

	createEffect(() => {
		if (open()) {
			trackEvent('upsellClicked', { productType: 'troonAccess', location: props.location });
		}
	});

	return (
		<div class="relative flex flex-row flex-wrap items-center justify-between gap-8 rounded bg-gradient-to-r from-neutral-950 to-brand-700 p-6 text-white">
			<span class="absolute inset-0 -z-0 cursor-pointer" onClick={() => setOpen(true)} />
			<div class="flex flex-col gap-4">
				<TroonAccess class="w-full min-w-36 max-w-48" />
				<p>Save up to 50% on tee times at 150+ courses with the all-new Troon Access program.</p>
			</div>
			<Dialog key="access-products" open={open()} onOpenChange={setOpen}>
				<DialogTrigger appearance="primary" class="relative z-0 shrink grow-0">
					Join today
				</DialogTrigger>
				<AccessProductDialogContent onCancel={() => setOpen(false)} />
			</Dialog>
		</div>
	);
}
